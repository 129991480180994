import * as XLSX from "xlsx";

export function isDevelopment() {
  return process.env.NODE_ENV === "development";
}

export function isDevBuild() {
  return process.env.REACT_APP_DEV === "1";
}

export function getCreditials() {
  let parseToken = document.cookie.match(/token=(\S+)/);

  return isDevelopment()
    ? {
        subdomain: localStorage.getItem("dev_subdomain"),
        token: localStorage.getItem("dev_token"),
      }
    : {
        subdomain: window.location.host.split(".")[0],
        token: parseToken && parseToken[1],
      };
}

export const downloadToExcel = (data, name) => {
  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.json_to_sheet(data);
  XLSX.utils.book_append_sheet(workbook, worksheet);
  XLSX.writeFile(workbook, `${name}.xlsx`);
};

export const convertExcelToJson = async (file) => {
  return await new Promise((resolve, reject) => {
    if (file) {
      const reader = new FileReader();

      reader.onload = (event) => {
        try {
          const data = new Uint8Array(event.target.result);
          const workbook = XLSX.read(data, { type: "array" });

          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const jsonData = XLSX.utils.sheet_to_json(worksheet);

          resolve(jsonData);
        } catch (error) {
          reject(error);
        }
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsArrayBuffer(file);
    } else {
      reject(new Error("No file provided"));
    }
  });
};
