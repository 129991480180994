import { Table } from "antd";
import React from "react";
import { formatCurrency } from "utils/formatCurrency";
import { t } from "i18n";

const productTypeOptions = [
  { label: "Plastinka", value: 1 },
  { label: "Podushka", value: 2 },
];

const productTasteOptions = [
  { value: 1, label: "Shirin" },
  { value: 2, label: "Nordon" },
];

const columns = [
  {
    title: t("Name"),
    width: 100,
    dataIndex: "product_name",
    key: "product_name",
  },
  {
    title: t("Price"),
    width: 100,
    dataIndex: "product_price",
    key: "product_price",
    render: (record) => formatCurrency(record),
  },
  {
    title: t("product_type"),
    width: 100,
    dataIndex: "product_type",
    key: "product_type",
    render: (record) =>
      productTypeOptions.find((option) => option.value === record).label,
  },
  {
    title: t("product_taste"),
    width: 130,
    dataIndex: "product_taste",
    key: "product_taste",
    render: (record) =>
      productTasteOptions.find((option) => option.value === record).label,
  },
];

export default function ExpandedRow({ record }) {
  return (
    <Table
      style={{ marginBottom: 20 }}
      columns={columns}
      pagination={false}
      rowKey={(record) => record.id}
      dataSource={[record]}
    />
  );
}
