import {
  HomeOutlined,
  BookOutlined,
  UserOutlined,
  InboxOutlined,
  InstagramOutlined,
  FileImageOutlined,
  AppstoreOutlined,
  FileTextOutlined,
  UsergroupAddOutlined,
  VideoCameraOutlined,
  UnorderedListOutlined,
  ProfileOutlined,
  ScanOutlined,
  TeamOutlined,
  TagsOutlined,
} from "@ant-design/icons";

const Icons = {
  HomeOutlined,
  BookOutlined,
  UserOutlined,
  TeamOutlined,
  InboxOutlined,
  InstagramOutlined,
  FileImageOutlined,
  AppstoreOutlined,
  FileTextOutlined,
  UsergroupAddOutlined,
  VideoCameraOutlined,
  UnorderedListOutlined,
  ProfileOutlined,
  ScanOutlined,
  TagsOutlined,
};

export default Icons;
