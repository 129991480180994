import i18next from "i18next";
import { initReactI18next } from "react-i18next";
// import { updateUserLocale } from "redux/actions/user";
// import { dispatch } from "redux/store";

const localeResources = {
  uz: {
    translation: require("./locale/uz.json"),
  },
  ru: {
    translation: require("./locale/ru.json"),
  },
  en: {
    translation: require("./locale/en.json"),
  },
};

export const getLang = () => localStorage.getItem("lang") || "ru";

export const i18n = i18next.use(initReactI18next).init({
  resources: localeResources,
  lng: getLang(),
  interpolation: {
    escapeValue: false,
  },
});

/**
 * Please use it only in extreme case. Use "useTranslation" hook instead
 * @param {string} string source of translation
 * @returns Translated string
 */
export function t(string) {
  return i18next.t(string);
}

export const changeLanguage = (lang) => {
  localStorage.setItem("lang", lang);
  i18next.changeLanguage(lang);
  setTimeout(() => {
    window.location.reload();
  }, 100);
};
