import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import React from "react";

const Loading = ({ align = "center", cover = "content", size = 35 }) => {
	return (
		<div className={`loading text-${align} cover-${cover}`}>
			<Spin
				indicator={<LoadingOutlined style={{ fontSize: size }} spin />}
				size="small"
			/>
		</div>
	);
};

export default Loading;
