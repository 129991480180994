import QueryString from "qs";
import { getDataMiddleware } from "./api.utils";
import mainApiInstence from "./mainApiInstence";

export class UserApi {
  static async get(query: any) {
    return mainApiInstence
      .get(`/user?${QueryString.stringify(query)}`)
      .then(getDataMiddleware);
  }

  static async getOrdersById(id: string, query: any) {
    return mainApiInstence
      .get(`/user/orders/${id}?${QueryString.stringify(query)}`)
      .then(getDataMiddleware);
  }

  static async updateCashById(query: any) {
    return mainApiInstence
      .patch(`/user/update?${QueryString.stringify(query)}`)
      .then(getDataMiddleware);
  }
}
