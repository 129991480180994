import QueryString from "qs";
import { getDataMiddleware } from "./api.utils";
import mainApiInstence from "./mainApiInstence";

export class ApplicationApi {
  static async get(query: any) {
    return mainApiInstence
      .get(`/order?${QueryString.stringify(query)}`)
      .then(getDataMiddleware);
  }
  static async edit(id: any, data: any) {
    return mainApiInstence.patch(`/order/${id}`, data).then(getDataMiddleware);
  }
  static async getStat(query: any) {
    return mainApiInstence
      .get(`/order/stat?${QueryString.stringify(query)}`)
      .then(getDataMiddleware);
  }

  static async sendSales(id: any) {
    return mainApiInstence.post(`/order/sales/${id}`).then(getDataMiddleware);
  }

  static async getBots() {
    return mainApiInstence.get("/bots").then(getDataMiddleware);
  }

  static async updateBot(query: { name: string; status: string }) {
    return mainApiInstence
      .patch(`/bots?${QueryString.stringify(query)}`)
      .then(getDataMiddleware);
  }
}
