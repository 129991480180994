import AuthLayout from "../layouts/auth-layout";
import React from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import NotFound from "../components/pages/404";
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from "../config/AppConfig";
import AppLayout from "../layouts/app-layout";

function RouteInterceptor({ children, isAuthenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: AUTH_PREFIX_PATH,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export const Views = (props) => {
  const { token } = props;

  return (
    <Switch>
      <Route exact path="/">
        <Redirect to={APP_PREFIX_PATH} />
      </Route>
      <Route exact path="/404" component={NotFound} />

      <Route path={AUTH_PREFIX_PATH}>
        <AuthLayout />
      </Route>

      <RouteInterceptor path={APP_PREFIX_PATH} isAuthenticated={token}>
        <AppLayout />
      </RouteInterceptor>
    </Switch>
  );
};

const mapStateToProps = ({ auth }) => {
  const { token } = auth;
  return { token };
};

export default withRouter(connect(mapStateToProps)(Views));
