import QueryString from "qs";
import { getDataMiddleware } from "./api.utils";
import mainApiInstence from "./mainApiInstence";

export class ProductApi {
  static async get(query: any) {
    return mainApiInstence
      .get(`/product?${QueryString.stringify(query)}`)
      .then(getDataMiddleware);
  }
  static async create(data: any) {
    return mainApiInstence.post("/product", data).then(getDataMiddleware);
  }
  static async edit(id: any, data: any) {
    return mainApiInstence
      .patch(`/product/${id}`, data)
      .then(getDataMiddleware);
  }
  static async delete(id: any) {
    return mainApiInstence.delete(`/product/${id}`).then(getDataMiddleware);
  }
}
