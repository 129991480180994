import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input, Modal, Row } from "antd";
import { MessagesService } from "services/Messages";
import { PromocodeApi } from "api/PromocodeApi";
import { useTranslation } from "react-i18next";
import { EventUsersApi } from "api/EventUsersApi";

const requiredOptions = [
  {
    required: true,
    message: "Invalid field",
  },
];

export default function UserModal({ open, setOpen, setData }) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [user, setUser] = useState({});
  const [loader, setLoader] = useState(false);

  const onFinish = async (values) => {
    setLoader(true);
    try {
      if (open.content?.id) {
        const res = await EventUsersApi.userUpdate(values);
        setData((prevState) => ({
          ...prevState,
          items: prevState.items.map((item) => {
            if (item.user_id === res[0].user_id) {
              return res[0];
            }
            return item;
          }),
        }));
      } else {
        const res = await EventUsersApi.create(values);
        setData((prevState) => ({
          ...prevState,
          items: [...prevState.items, res],
        }));
      }
      setOpen({ ...open, isOpen: false, content: null });
    } catch (error) {
      MessagesService.error(error.message);
    }
    setLoader(false);
  };

  const fetchUserData = async () => {
    try {
      const res = await PromocodeApi.getUserById(open.content.id);
      setUser(res);
    } catch (error) {
      MessagesService.error(error);
    }
  };

  useEffect(() => form.setFieldsValue(user), [user]);

  useEffect(() => {
    if (open.content?.id) {
      fetchUserData();
    }
  }, [open]);

  return (
    <Modal
      title="User Details"
      centered
      closable={false}
      open={open.isOpen}
      onOk={() => setOpen({ ...open, isOpen: false, content: null })}
      onCancel={() => setOpen({ ...open, isOpen: false, content: null })}
      width={700}
      footer={[
        <Button onClick={form.submit} type="primary" loading={loader}>
          {t(open.content?.id ? "Update" : "Save")}
        </Button>,
      ]}
    >
      <Form form={form} onFinish={onFinish}>
        <Row
          gutter={{
            xs: 8,
            sm: 16,
            md: 24,
            lg: 32,
          }}
        >
          <Col span={12}>
            <label htmlFor="full_name">{t("user_full_name")}</label>
            <Form.Item name="id" hidden />
            <Form.Item name="chat_id" hidden />
            <Form.Item name="username" hidden />
            <Form.Item
              name="full_name"
              className="ant-alert-content"
              rules={requiredOptions}
            >
              <Input placeholder={t("user_full_name")} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <label htmlFor="username">{t("telegram_username")}</label>
            <Form.Item name="username">
              <Input placeholder="@username" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <label htmlFor="phone_number">{t("Phone number")}</label>
            <Form.Item name="phone_number" rules={requiredOptions}>
              <Input placeholder="+998991234567" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <label htmlFor="age">{t("age")}</label>
            <Form.Item name="age" rules={requiredOptions}>
              <Input placeholder="10-80" type="number" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <label htmlFor="address">{t("Address")}</label>
            <Row className="flex">
              <Form.Item name="address" className="ant-alert-content" required>
                <Input placeholder={t("address_placeholder")} />
              </Form.Item>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
