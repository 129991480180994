import { message } from "antd";
import { t } from "../i18n";

export class MessagesService {
  static success(text = "success") {
    message.success(t(text));
  }
  static error(text = "error") {
    message.error(t(text));
  }

  static axiosError(err: any, text = "") {
    if (err?.data?.errors) {
      const first = Object.entries(err.data.errors)[0];
      this.error(`${first[0]}: ${first[1]}`);
    } else {
      this.error(err?.message || text);
    }
  }
}
