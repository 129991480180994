import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input, Modal, Row, Select } from "antd";
import { MessagesService } from "services/Messages";
import { PromocodeApi } from "api/PromocodeApi";
import { debounce } from "utils/debounce";
const { Option } = Select;

const requiredOptions = [
  {
    required: true,
    message: "Invalid field",
  },
];

const SearchOptions = (results) => {
  return results.map((item) => (
    <Option key={item.id} value={item.id}>
      <div className="d-flex align-items-center">
        {item.full_name ?? item.username}
      </div>
    </Option>
  ));
};

export default function UserModal({ open, setOpen, setData }) {
  const [form] = Form.useForm();
  const [user, setUser] = useState({});
  const [searchResults, setSearchResults] = useState([]);
  const [loaders, setLoaders] = useState({
    userL: false,
    saveL: false,
    content: null,
  });

  const searchUser = async (keyword) => {
    setLoaders({ ...loaders, userL: true });
    try {
      const res = await PromocodeApi.searchUser(keyword);
      setSearchResults(res.items);
    } catch (error) {
      MessagesService.error(error.message);
    }
    setLoaders({ ...loaders, userL: false });
  };

  const onSearch = async (keyword) => {
    if (keyword) {
      debounce(() => searchUser(keyword), "multiple-select-form-item", 500);
    }
  };

  const onSelect = async (id) => {
    const selectedItem = searchResults.find(
      (searchUser) => searchUser.id === id
    );
    setUser({
      ...selectedItem,
      full_name: selectedItem.full_name ?? "",
    });
  };

  const onFinish = async (values) => {
    values.status = "active";
    const { id, ...filteredUser } = values;
    try {
      const res = await PromocodeApi.userUpdate(filteredUser);
      setData((prevState) => ({
        ...prevState,
        items: prevState.items.map((item) => {
          if (
            item.user_id === res[0].user_id ||
            item.id === open.content.promoId
          ) {
            return {
              ...item,
              full_name: res[0].full_name,
              user_id: res[0].user_id,
              status: res[0].status,
            };
          }
          return item;
        }),
      }));
      setOpen({ ...open, uIsOpen: false, content: null });
    } catch (error) {
      MessagesService.error(error.message);
    }
  };

  const fetchUserData = async () => {
    try {
      const res = await PromocodeApi.getUserById(open.content.user_id);
      setUser(res);
    } catch (error) {
      MessagesService.error(error);
    }
  };

  useEffect(() => form.setFieldsValue(user), [user]);

  useEffect(() => {
    if (open.content.user_id) {
      fetchUserData();
    }
  }, [open]);

  useEffect(() => {
    setUser({ ...form.getFieldsValue(), ...loaders.content });
  }, [loaders.content]);

  return (
    <>
      <Modal
        title={
          <Row
            gutter={{
              xs: 8,
              sm: 16,
              md: 24,
              lg: 32,
            }}
          >
            <Col span={12}>
              <h4 className="m-0">User Details</h4>
            </Col>
            <Col span={12}>
              <Select
                style={{ marginRight: "40px", width: "100%" }}
                showSearch
                filterOption={false}
                loading={loaders.userL}
                onSearch={onSearch}
                placeholder="Search user full name or phone number"
                onSelect={onSelect}
              >
                {SearchOptions(searchResults)}
              </Select>
            </Col>
          </Row>
        }
        centered
        closable={false}
        open={open.uIsOpen}
        onOk={() => setOpen({ ...open, uIsOpen: false, content: null })}
        onCancel={() => setOpen({ ...open, uIsOpen: false, content: null })}
        width={1000}
        footer={[
          <Button onClick={form.submit} type="primary" loading={loaders.saveL}>
            Save
          </Button>,
        ]}
      >
        <Form form={form} onFinish={onFinish}>
          <Row
            gutter={{
              xs: 8,
              sm: 16,
              md: 24,
              lg: 32,
            }}
          >
            <Col span={12}>
              <label htmlFor="full_name">Full name</label>
              <Form.Item
                hidden
                name="promoId"
                initialValue={open.content.promoId}
              />
              <Form.Item name="id" hidden />
              <Form.Item name="chat_id" hidden />
              <Form.Item name="user_id" initialValue={user?.id} hidden />
              <Form.Item name="username" hidden />
              <Form.Item
                name="full_name"
                className="ant-alert-content"
                rules={requiredOptions}
              >
                <Input placeholder="Full name" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <label htmlFor="phone_number">Phone number</label>
              <Form.Item name="phone_number" rules={requiredOptions}>
                <Input placeholder="+998991234567" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <label htmlFor="age">Age</label>
              <Form.Item name="age" rules={requiredOptions}>
                <Input placeholder="10-80" type="number" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <label htmlFor="address">Address</label>
              <Row className="flex">
                {/* <Form.Item name="lat" hidden />
                <Form.Item name="long" hidden /> */}
                <Form.Item
                  name="address"
                  className="ant-alert-content"
                  required
                >
                  <Input placeholder="User address" />
                </Form.Item>
                {/* <Button
                  type="primary"
                  className="ant-alert-action"
                  onClick={() =>
                    setLoaders({
                      ...loaders,
                      isMapOpen: true,
                    })
                  }
                >
                  Open map
                </Button> */}
              </Row>
            </Col>
          </Row>
        </Form>
      </Modal>
      {/* {loaders.isMapOpen && (
        <MapModal open={{ ...loaders, content: user }} setOpen={setLoaders} />
      )} */}
    </>
  );
}
