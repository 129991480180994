import QueryString from "qs";
import { getDataMiddleware } from "./api.utils";
import mainApiInstence from "./mainApiInstence";

export class EventUsersApi {
  static async get(query: any) {
    return mainApiInstence
      .get(`/event-users?${QueryString.stringify(query)}`)
      .then(getDataMiddleware);
  }

  static async getUserById(id: string) {
    return mainApiInstence.get(`/event-users/${id}`).then(getDataMiddleware);
  }

  static async create(data: any) {
    return mainApiInstence.post(`/event-users`, data).then(getDataMiddleware);
  }

  static async userUpdate(data: any) {
    return mainApiInstence
      .post(`/event-users/update`, data)
      .then(getDataMiddleware);
  }

  static async delete(id: string) {
    return mainApiInstence.delete(`/event-users/${id}`).then(getDataMiddleware);
  }
}
