import React, { useState } from "react";
import { Button, Col, Form, Input, Modal, Row, Select } from "antd";
import { t } from "i18n";
import { PromocodeApi } from "api/PromocodeApi";
import { MessagesService } from "services/Messages";
import { randomString } from "utils/string";
import { formRules } from "utils/form";

const productTasteOptions = [
  { value: 1, label: "Shirin" },
  { value: 2, label: "Nordon" },
];

const productTypeOptions = [
  { value: 1, label: t("Plastinka") },
  { value: 2, label: t("Podushka") },
];

export default function PromocodeModal({ open, setOpen, setData }) {
  const [form] = Form.useForm();
  const [loaders, setLoaders] = useState({
    productL: false,
    promoL: false,
    saveL: false,
  });

  const generateCode = async () => {
    const code = randomString();
    form.setFieldValue("promocode", code);
  };

  const onFinish = async (values) => {
    setLoaders({ ...loaders, promoL: true });
    try {
      if (open.content) {
        const res = await PromocodeApi.update({ ...open.content, ...values });
        setData((prevState) => ({
          ...prevState,
          items: prevState.items.map((item) =>
            item.id === res[0].id ? res[0] : item
          ),
        }));
      } else {
        const res = await PromocodeApi.create({ promocodes: [values] });
        setData((prevState) => ({
          ...prevState,
          items: [...res, ...prevState.items],
        }));
      }
      setOpen({ ...open, pIsOpen: false, content: null });
    } catch (error) {
      MessagesService.error(error.message);
    }
    setLoaders({ ...loaders, promoL: false });
  };

  return (
    <Modal
      title={t("Promocode details")}
      centered
      open={open.pIsOpen}
      onCancel={() => setOpen({ ...open, pIsOpen: false, content: null })}
      width={1000}
      footer={[
        <Button
          onClick={form.submit}
          type="primary"
          form="submit"
          loading={loaders.saveL}
        >
          {t("Save")}
        </Button>,
      ]}
    >
      <Form form={form} onFinish={onFinish} initialValues={open.content}>
        <Row
          gutter={{
            xs: 8,
            sm: 16,
            md: 24,
            lg: 32,
          }}
        >
          <Col span={12}>
            <label htmlFor="full_name">{t("user_full_name")}</label>
            <Form.Item name="full_name">
              <Input placeholder={t("user_full_name")} readOnly />
            </Form.Item>
          </Col>
          <Col span={12}>
            <label htmlFor="product_name">{t("product_name")}</label>
            <Row className="flex">
              <Form.Item
                name="product_name"
                className="ant-alert-content"
                rules={formRules}
              >
                <Input placeholder={t("product_name")} />
              </Form.Item>
            </Row>
          </Col>
          <Col span={12}>
            <label htmlFor="product_type">{t("product_type")}</label>
            <Form.Item name="product_type" initialValue={1}>
              <Select options={productTypeOptions} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <label htmlFor="product_taste">{t("product_taste")}</label>
            <Form.Item name="product_taste" initialValue={1}>
              <Select options={productTasteOptions} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <label htmlFor="product_price">{t("product_price")}</label>
            <Form.Item name="product_price" rules={formRules}>
              <Input placeholder={t("product_price")} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <label htmlFor="promocode">{t("Promocode")}</label>
            <Row>
              <Form.Item
                className="ant-alert-content"
                name="promocode"
                rules={formRules}
              >
                <Input placeholder={t("Promocode")} />
              </Form.Item>
              <Button
                className="ant-alert-action"
                onClick={generateCode}
                type="primary"
              >
                {t("generate_code")}
              </Button>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
