import Loading from "../../components/shared-components/Loading";
import React, { Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { APP_PREFIX_PATH } from "../../config/AppConfig";
import Dashboard from "./dashboard";
import Application from "./applications";
import Product from "./product";
import Category from "./category";
import Customers from "./customer";
import UserOrders from "./user/[id]";
import Promocodes from "./promocode";
import EventUsers from "./event-users";

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route path={`${APP_PREFIX_PATH}/dashboard`} component={Dashboard} />
        <Route path={`${APP_PREFIX_PATH}/product`} component={Product} />
        <Route path={`${APP_PREFIX_PATH}/category`} component={Category} />
        <Route path={`${APP_PREFIX_PATH}/customer`} component={Customers} />
        <Route path={`${APP_PREFIX_PATH}/user/:id`} component={UserOrders} />
        <Route
          path={`${APP_PREFIX_PATH}/application`}
          component={Application}
        />
        <Route path={`${APP_PREFIX_PATH}/promocode`} component={Promocodes} />
        <Route path={`${APP_PREFIX_PATH}/event-users`} component={EventUsers} />
        <Redirect to={`${APP_PREFIX_PATH}/dashboard`} />
      </Switch>
    </Suspense>
  );
};

export default React.memo(AppViews);
