import { Tag } from "antd";
import { t } from "i18n";

const OrderStatus = {
  created: t("Created"),
  active: t("Active"),
  canceled: t("Canceled"),
  confirmed: t("Confirmed"),
  delivered: t("Delivered"),
};

export default OrderStatus;




export const StatusSwitcher = (record) => {
  if (record === "created") {
    return <Tag color={"blue"}>{OrderStatus["created"]}</Tag>;
  }
  if (record === "active") {
    return <Tag color={"green"}>{OrderStatus["active"]}</Tag>;
  }
  if (record === "canceled") {
    return <Tag color={"red"}>{OrderStatus["canceled"]}</Tag>;
  }
  if (record === "confirmed") {
    return <Tag color={"#9c54cc"}>{OrderStatus["confirmed"]}</Tag>;
  }
  if (record === "delivered") {
    return <Tag color={"#87d068"}>{OrderStatus["delivered"]}</Tag>;
  }
}


