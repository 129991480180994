import QueryString from "qs";
import { getDataMiddleware } from "./api.utils";
import mainApiInstence from "./mainApiInstence";

export class PromocodeApi {
  
  static async get(query: any) {
    return mainApiInstence
      .get(`/promocode?${QueryString.stringify(query)}`)
      .then(getDataMiddleware);
  }

  static async getAll(query: any) {
    return mainApiInstence
      .get(`/promocode/all?${QueryString.stringify(query)}`)
      .then(getDataMiddleware);
  }

  static async getUserById(id: string) {
    return mainApiInstence.get(`/promocode/user/${id}`).then(getDataMiddleware);
  }

  static async searchUser(keyword: string) {
    return mainApiInstence
      .get(`/promocode/search-user?keyword=${keyword}`)
      .then(getDataMiddleware);
  }

  static async create(data: any) {
    return mainApiInstence.post(`/promocode`, data).then(getDataMiddleware);
  }

  static async userUpdate(data: any) {
    return mainApiInstence
      .post(`/promocode/user/update`, data)
      .then(getDataMiddleware);
  }

  static async update(data: any) {
    return mainApiInstence
      .patch(`/promocode/${data.id}`, data)
      .then(getDataMiddleware);
  }

  static async delete(id: string) {
    return mainApiInstence.delete(`/promocode/${id}`).then(getDataMiddleware);
  }

  static async deletePromocodes(query: any) {
    return mainApiInstence
      .delete(`/promocode?${QueryString.stringify(query)}`)
      .then(getDataMiddleware);
  }
}
