import QueryString from "qs";
import { getDataMiddleware } from "./api.utils";
import mainApiInstence from "./mainApiInstence";

export class CategoryApi {
  static async get(query: any) {
    return mainApiInstence
      .get(`/category?${QueryString.stringify(query)}`)
      .then(getDataMiddleware);
  }
  static async create(data: any) {
    return mainApiInstence.post("/category", data).then(getDataMiddleware);
  }
  static async edit(id: any, data: any) {
    return mainApiInstence
      .patch(`/category/${id}`, data)
      .then(getDataMiddleware);
  }
  static async delete(id: any) {
    return mainApiInstence.delete(`/category/${id}`).then(getDataMiddleware);
  }
}
